<template>
  <div class="my-upload">
    <el-upload
    class="avatar-uploader"
    accept=".jpg,.png,.jpeg"
    action="https://upload.qiniup.com"
    :show-file-list="false"
    :on-error="handleError"
    :data="{token}"
    :headers="header"
    :on-success="handleAvatarSuccess"
    :before-upload="beforeAvatarUpload">
    <img v-if="coverImage" :src="coverImage" class="avatar">
    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
</el-upload>
  </div>
</template>
<script>
import { getToken } from '@/api/tips'

export default {
  name: 'fanhan-upload',
  props: {
    coverImage: String
  },
  data () {
    return {
      token: '',
      isPic: '',
      isSize: ''
    }
  },
  computed: {
    header () {
      return {
        'x-client-type': 'Web',
        'x-client-version': ''
      }
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    async init () {
      const res = await getToken()
      if (res.code === 0) {
        this.token = res.data.token
      }
    },
    handleError () {
      this.$notify({
        title: '警告',
        message: '上传图片失败',
        type: 'error'
      })
    },
    beforeAvatarUpload (file) {
      if (['image/png', 'image/png', 'image/jpeg'].includes(file.type)) {
        this.isPic = true
      } else {
        this.isPic = false
      }
      if (!this.isPic) {
        this.$notify({
          title: '警告',
          message: '请上传图片',
          type: 'error'
        })
        return false
      }
      // const URL = window.URL || window.webkitURL
      // const image = new Image()
      // image.src = URL.createObjectURL(file)
      // image.onload = () => {
      //   // console.log((image.width / image.height).toFixed(2))
      //   const one = +(image.width / image.height).toFixed(2)
      //   this.isSize = (one === 2.35)
      //   if (!this.isSize) {
      //     this.$notify({
      //       title: '警告',
      //       message: '图片比例不是2.35 : 1 , 请重新上传',
      //       type: 'error'
      //     })
      //     return false
      //   }
      // }
    },
    handleAvatarSuccess (file) {
      if (this.isPic) {
        this.coverImage = file.url
        this.$notify({
          title: '成功',
          message: '图片上传成功',
          type: 'success'
        })
        this.$emit('setImageUrl', file.url)
      }
    }
  }
}
</script>

<style lang="less" scoped>
.my-upload{
  min-width: 100px;
  max-width: 100px;
  height: 100px;
  overflow: hidden;
  border: 1px solid #D9D9D9 ;
  .avatar-uploader .el-upload {
    border: 1px dashed #D9D9D9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
  }
  .avatar {
    width: 100px;
    height: 100px;
    display: block;
  }
}
</style>
